import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FaqComponent } from '@3dk/3style';
import convertRichText from '../../../../../utils/convertRichText';
import faqToGoogleMarkupSchema from '../../../../../utils/seo-helpers/FaqSchemaMapper';
import renderOptions from '../../../../../utils/richTextRenderOptions/defaultRenderOptions';

const mapGenericContent = (contentData, path) => {
  if (!contentData) {
    return null;
  }

  const nonEnglishLetters = /([^(A-Za-z)])+/g;
  const items = (contentData.questions || []).map((question, index) => ({
    ...question,
    itemId: question.itemId || (question.question || `question-${index}`).replace(nonEnglishLetters, '-'),
    answer: convertRichText(question.answer, renderOptions),
  }));
  const sectionId = (contentData.sectionTitle || `${path}-faq`).replace(nonEnglishLetters, '-');
  const { sectionTitle } = contentData;
  return { items, sectionId, sectionTitle };
};

const Faq = ({ content }) => {
  const location = useLocation();

  // anchor doesn't work Server Side: #-part of the URL isn't sent to the server
  // expandedPanel is only set Client Side
  const [expandedPanel, setExpandedPanel] = useState(null);
  useEffect(() => {
    setExpandedPanel(location.hash?.replace('#', ''));
  }, [location]);

  const faqContent = mapGenericContent(content, location.path);

  if (faqContent) {
    return (
      <>
        {content.questions?.length > 0 && (
          <Helmet>
            <script type="application/ld+json">{JSON.stringify(faqToGoogleMarkupSchema(content.questions))}</script>
          </Helmet>
        )}
        <FaqComponent content={faqContent} expandedPanel={expandedPanel} />
      </>
    );
  }
  return null;
};

Faq.propTypes = {
  content: PropTypes.shape({
    sectionId: PropTypes.string,
    sectionTitle: PropTypes.string,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        itemId: PropTypes.string,
        question: PropTypes.string.isRequired,
        answer: PropTypes.node.isRequired,
      }),
    ),
  }),
};

Faq.defaultProps = {
  content: null,
};

export default Faq;
