import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import safeParse from '../safe-json';

const faqToGoogleMarkupSchema = (questions) => ({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: questions
    .map(({ question, answer }) => ({
      '@type': 'Question',
      name: question,
      acceptedAnswer: { '@type': 'Answer', text: documentToPlainTextString(safeParse(answer)) },
    }))
    .filter((question) => question.name && question.acceptedAnswer.text),
});

export default faqToGoogleMarkupSchema;
